<div id="contact" class="main-banner">
    <div class="container">
        <div class="row  justify-content-md-center">
            <div class="col-lg-7 col-md-12">
                <div class="hero-content pt-70 text-center">
                    <h1>Get in Touch</h1>
                    <p>Would you like to discover more about Septilink?<br/>
                        Don't hesitate to get in touch via phone or email today!</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-area pb-100">
    <div class="container">
        <div class="row justify-content-center">
            <!--
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <div class="contact-form-container">
                        <div class="title">
                            <h5 class="orange-title">Send us a message</h5>
                        </div>
                        <app-contact-form></app-contact-form>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-md-12"></div>
            -->
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <div class="title">
                        <h5 class="orange-title">Speak with a team member</h5>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="contact-info-container">
                                <ul class="contact-info-list">
                                    <li>
                                        <i class="fas fa-phone-volume"></i>
                                        <span>Phone</span>
                                        <a href="tel:5127107969">(512) 710-7969</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="contact-info-container">
                                <ul class="contact-info-list">
                                    <li>
                                        <i class="fas fa-envelope-open-text"></i>
                                        <span>Email</span>
                                        <a href="mailto:support@septilink.com">support@septilink.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


