import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerComponent {
  constructor() { }

  ngOnInit() {
  }
}
