/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateContactData: OnCreateContactDataSubscription;
  onUpdateContactData: OnUpdateContactDataSubscription;
  onDeleteContactData: OnDeleteContactDataSubscription;
  onCreateEmailList: OnCreateEmailListSubscription;
  onUpdateEmailList: OnUpdateEmailListSubscription;
  onDeleteEmailList: OnDeleteEmailListSubscription;
};

export type CreateContactDataInput = {
  id?: string | null;
  fullName: string;
  email: string;
  phoneNumber: string;
  msg: string;
  isBetaTester: boolean;
  isInvestor: boolean;
};

export type ModelContactDataConditionInput = {
  fullName?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phoneNumber?: ModelStringInput | null;
  msg?: ModelStringInput | null;
  isBetaTester?: ModelBooleanInput | null;
  isInvestor?: ModelBooleanInput | null;
  and?: Array<ModelContactDataConditionInput | null> | null;
  or?: Array<ModelContactDataConditionInput | null> | null;
  not?: ModelContactDataConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ContactData = {
  __typename: "ContactData";
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  msg: string;
  isBetaTester: boolean;
  isInvestor: boolean;
  createdAt: string;
  updatedAt: string;
};

export type UpdateContactDataInput = {
  id: string;
  fullName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  msg?: string | null;
  isBetaTester?: boolean | null;
  isInvestor?: boolean | null;
};

export type DeleteContactDataInput = {
  id: string;
};

export type CreateEmailListInput = {
  id?: string | null;
  email: string;
};

export type ModelEmailListConditionInput = {
  email?: ModelStringInput | null;
  and?: Array<ModelEmailListConditionInput | null> | null;
  or?: Array<ModelEmailListConditionInput | null> | null;
  not?: ModelEmailListConditionInput | null;
};

export type EmailList = {
  __typename: "EmailList";
  id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEmailListInput = {
  id: string;
  email?: string | null;
};

export type DeleteEmailListInput = {
  id: string;
};

export type ModelContactDataFilterInput = {
  id?: ModelIDInput | null;
  fullName?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phoneNumber?: ModelStringInput | null;
  msg?: ModelStringInput | null;
  isBetaTester?: ModelBooleanInput | null;
  isInvestor?: ModelBooleanInput | null;
  and?: Array<ModelContactDataFilterInput | null> | null;
  or?: Array<ModelContactDataFilterInput | null> | null;
  not?: ModelContactDataFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelContactDataConnection = {
  __typename: "ModelContactDataConnection";
  items: Array<ContactData | null>;
  nextToken?: string | null;
};

export type ModelEmailListFilterInput = {
  id?: ModelIDInput | null;
  email?: ModelStringInput | null;
  and?: Array<ModelEmailListFilterInput | null> | null;
  or?: Array<ModelEmailListFilterInput | null> | null;
  not?: ModelEmailListFilterInput | null;
};

export type ModelEmailListConnection = {
  __typename: "ModelEmailListConnection";
  items: Array<EmailList | null>;
  nextToken?: string | null;
};

export type ModelSubscriptionContactDataFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  fullName?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  phoneNumber?: ModelSubscriptionStringInput | null;
  msg?: ModelSubscriptionStringInput | null;
  isBetaTester?: ModelSubscriptionBooleanInput | null;
  isInvestor?: ModelSubscriptionBooleanInput | null;
  and?: Array<ModelSubscriptionContactDataFilterInput | null> | null;
  or?: Array<ModelSubscriptionContactDataFilterInput | null> | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelSubscriptionEmailListFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  email?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionEmailListFilterInput | null> | null;
  or?: Array<ModelSubscriptionEmailListFilterInput | null> | null;
};

export type CreateContactDataMutation = {
  __typename: "ContactData";
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  msg: string;
  isBetaTester: boolean;
  isInvestor: boolean;
  createdAt: string;
  updatedAt: string;
};

export type UpdateContactDataMutation = {
  __typename: "ContactData";
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  msg: string;
  isBetaTester: boolean;
  isInvestor: boolean;
  createdAt: string;
  updatedAt: string;
};

export type DeleteContactDataMutation = {
  __typename: "ContactData";
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  msg: string;
  isBetaTester: boolean;
  isInvestor: boolean;
  createdAt: string;
  updatedAt: string;
};

export type CreateEmailListMutation = {
  __typename: "EmailList";
  id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEmailListMutation = {
  __typename: "EmailList";
  id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteEmailListMutation = {
  __typename: "EmailList";
  id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type GetContactDataQuery = {
  __typename: "ContactData";
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  msg: string;
  isBetaTester: boolean;
  isInvestor: boolean;
  createdAt: string;
  updatedAt: string;
};

export type ListContactDataQuery = {
  __typename: "ModelContactDataConnection";
  items: Array<{
    __typename: "ContactData";
    id: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    msg: string;
    isBetaTester: boolean;
    isInvestor: boolean;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetEmailListQuery = {
  __typename: "EmailList";
  id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type ListEmailListsQuery = {
  __typename: "ModelEmailListConnection";
  items: Array<{
    __typename: "EmailList";
    id: string;
    email: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OnCreateContactDataSubscription = {
  __typename: "ContactData";
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  msg: string;
  isBetaTester: boolean;
  isInvestor: boolean;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateContactDataSubscription = {
  __typename: "ContactData";
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  msg: string;
  isBetaTester: boolean;
  isInvestor: boolean;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteContactDataSubscription = {
  __typename: "ContactData";
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  msg: string;
  isBetaTester: boolean;
  isInvestor: boolean;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateEmailListSubscription = {
  __typename: "EmailList";
  id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateEmailListSubscription = {
  __typename: "EmailList";
  id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteEmailListSubscription = {
  __typename: "EmailList";
  id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateContactData(
    input: CreateContactDataInput,
    condition?: ModelContactDataConditionInput
  ): Promise<CreateContactDataMutation> {
    const statement = `mutation CreateContactData($input: CreateContactDataInput!, $condition: ModelContactDataConditionInput) {
        createContactData(input: $input, condition: $condition) {
          __typename
          id
          fullName
          email
          phoneNumber
          msg
          isBetaTester
          isInvestor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateContactDataMutation>response.data.createContactData;
  }
  async UpdateContactData(
    input: UpdateContactDataInput,
    condition?: ModelContactDataConditionInput
  ): Promise<UpdateContactDataMutation> {
    const statement = `mutation UpdateContactData($input: UpdateContactDataInput!, $condition: ModelContactDataConditionInput) {
        updateContactData(input: $input, condition: $condition) {
          __typename
          id
          fullName
          email
          phoneNumber
          msg
          isBetaTester
          isInvestor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateContactDataMutation>response.data.updateContactData;
  }
  async DeleteContactData(
    input: DeleteContactDataInput,
    condition?: ModelContactDataConditionInput
  ): Promise<DeleteContactDataMutation> {
    const statement = `mutation DeleteContactData($input: DeleteContactDataInput!, $condition: ModelContactDataConditionInput) {
        deleteContactData(input: $input, condition: $condition) {
          __typename
          id
          fullName
          email
          phoneNumber
          msg
          isBetaTester
          isInvestor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteContactDataMutation>response.data.deleteContactData;
  }
  async CreateEmailList(
    input: CreateEmailListInput,
    condition?: ModelEmailListConditionInput
  ): Promise<CreateEmailListMutation> {
    const statement = `mutation CreateEmailList($input: CreateEmailListInput!, $condition: ModelEmailListConditionInput) {
        createEmailList(input: $input, condition: $condition) {
          __typename
          id
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEmailListMutation>response.data.createEmailList;
  }
  async UpdateEmailList(
    input: UpdateEmailListInput,
    condition?: ModelEmailListConditionInput
  ): Promise<UpdateEmailListMutation> {
    const statement = `mutation UpdateEmailList($input: UpdateEmailListInput!, $condition: ModelEmailListConditionInput) {
        updateEmailList(input: $input, condition: $condition) {
          __typename
          id
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEmailListMutation>response.data.updateEmailList;
  }
  async DeleteEmailList(
    input: DeleteEmailListInput,
    condition?: ModelEmailListConditionInput
  ): Promise<DeleteEmailListMutation> {
    const statement = `mutation DeleteEmailList($input: DeleteEmailListInput!, $condition: ModelEmailListConditionInput) {
        deleteEmailList(input: $input, condition: $condition) {
          __typename
          id
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEmailListMutation>response.data.deleteEmailList;
  }
  async GetContactData(id: string): Promise<GetContactDataQuery> {
    const statement = `query GetContactData($id: ID!) {
        getContactData(id: $id) {
          __typename
          id
          fullName
          email
          phoneNumber
          msg
          isBetaTester
          isInvestor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetContactDataQuery>response.data.getContactData;
  }
  async ListContactData(
    filter?: ModelContactDataFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListContactDataQuery> {
    const statement = `query ListContactData($filter: ModelContactDataFilterInput, $limit: Int, $nextToken: String) {
        listContactData(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            fullName
            email
            phoneNumber
            msg
            isBetaTester
            isInvestor
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListContactDataQuery>response.data.listContactData;
  }
  async GetEmailList(id: string): Promise<GetEmailListQuery> {
    const statement = `query GetEmailList($id: ID!) {
        getEmailList(id: $id) {
          __typename
          id
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEmailListQuery>response.data.getEmailList;
  }
  async ListEmailLists(
    filter?: ModelEmailListFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEmailListsQuery> {
    const statement = `query ListEmailLists($filter: ModelEmailListFilterInput, $limit: Int, $nextToken: String) {
        listEmailLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            email
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEmailListsQuery>response.data.listEmailLists;
  }
  OnCreateContactDataListener(
    filter?: ModelSubscriptionContactDataFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateContactData">>
  > {
    const statement = `subscription OnCreateContactData($filter: ModelSubscriptionContactDataFilterInput) {
        onCreateContactData(filter: $filter) {
          __typename
          id
          fullName
          email
          phoneNumber
          msg
          isBetaTester
          isInvestor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateContactData">>
    >;
  }

  OnUpdateContactDataListener(
    filter?: ModelSubscriptionContactDataFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateContactData">>
  > {
    const statement = `subscription OnUpdateContactData($filter: ModelSubscriptionContactDataFilterInput) {
        onUpdateContactData(filter: $filter) {
          __typename
          id
          fullName
          email
          phoneNumber
          msg
          isBetaTester
          isInvestor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateContactData">>
    >;
  }

  OnDeleteContactDataListener(
    filter?: ModelSubscriptionContactDataFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteContactData">>
  > {
    const statement = `subscription OnDeleteContactData($filter: ModelSubscriptionContactDataFilterInput) {
        onDeleteContactData(filter: $filter) {
          __typename
          id
          fullName
          email
          phoneNumber
          msg
          isBetaTester
          isInvestor
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteContactData">>
    >;
  }

  OnCreateEmailListListener(
    filter?: ModelSubscriptionEmailListFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEmailList">>
  > {
    const statement = `subscription OnCreateEmailList($filter: ModelSubscriptionEmailListFilterInput) {
        onCreateEmailList(filter: $filter) {
          __typename
          id
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateEmailList">>
    >;
  }

  OnUpdateEmailListListener(
    filter?: ModelSubscriptionEmailListFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEmailList">>
  > {
    const statement = `subscription OnUpdateEmailList($filter: ModelSubscriptionEmailListFilterInput) {
        onUpdateEmailList(filter: $filter) {
          __typename
          id
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateEmailList">>
    >;
  }

  OnDeleteEmailListListener(
    filter?: ModelSubscriptionEmailListFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEmailList">>
  > {
    const statement = `subscription OnDeleteEmailList($filter: ModelSubscriptionEmailListFilterInput) {
        onDeleteEmailList(filter: $filter) {
          __typename
          id
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteEmailList">>
    >;
  }
}
