import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MatDialogContent, MatDialogActions } from  '@angular/material/dialog';
import { DealerInterestFormComponent } from "../../contact/dealer-interest-form/dealer-interest-form.component";
declare let $: any;

@Component({
  selector: 'app-sl5',
  templateUrl: './sl5.component.html',
  styleUrls: ['./sl5.component.scss']
})
export class Sl5Component {
  featuresVisible: boolean = true;
  technologyVisible: boolean = false;
  specificationsVisible: boolean = false;

  constructor(private  dialog:  MatDialog) { }

  ngOnInit() {
  }

  openDealerInterestForm()
  {
    let dialogRef = this.dialog.open(DealerInterestFormComponent, {
      panelClass: "dialog-responsive",
      height: '90vh',
      maxWidth: '100vw',
      maxHeight: '90vh',
      data: { },
    });
  }

  public showSection(sectionName: string)
  {
    this.featuresVisible = false;
    this.technologyVisible = false;
    this.specificationsVisible = false;
    switch(sectionName)
    {
      case "features":
        this.featuresVisible = true;
        break;
      case "technology":
        this.technologyVisible = true;
        break;
      case "specifications":
        this.specificationsVisible = true;
        break;

    }

  }



}
