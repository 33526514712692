<div id="team" class="main-banner">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-lg-8 col-md-12">
        <div class="hero-content pt-70 text-center">
          <h1>Meet Our Team</h1>
          <p class="ps-1">Septilink™ is based in the Dallas/Fort Worth area with contributors
            from the US and Canada. Our team has a shared passion for innovative technology and
            commercial products that advance and enhance our customers’ lives. We come from various
            industry and technology backgrounds, including Agriculture, Vehicle OEM, Wireless
            Communications, Machine Control, Automation, Location, and Telematics.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<section id="clint" class="clint-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="person">
          <img src="../../../assets/img/team/bio/ClintMundtSeptilinkFounder.jpg" alt="Photo of Clint Mundt Septilink Founder">
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="team-bio-content">
          <h3>Clint Mundt</h3>
          <h5 class="orange-title">Septilink™ Founder</h5>
          <p>Raised on a mixed farm in western Canada, Clint began his career working with the Agriculture and Energy industries
            in the wireless technology space. In 2006, he founded Ranchview Inc. in Alberta, Canada, which Raven Industries
            ultimately acquired in 2009. Clint and his family relocated to the US, and he integrated their technology into
            Raven's products. In 2017, Clint, an avid believer in the American Dream, became a US citizen. The same year,
            he accepted a pivotal role at Kubota, spearheading the development of their North American telematics program and
            customer-facing technology. His leadership extended to initiatives such as establishing the Kubota Technology and
            Innovation Center North America and the position of CEO at AgJunction following its acquisition by Kubota. He thrives
            on collaborating with high-performance teams and is driven by a passion for crafting exceptional products complemented
            by unparalleled customer service.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="joy" class="joy-area ptb-100">
  <div class="container">
    <div class="row align-items-center reverse-container">
      <div class="col-lg-5 col-md-12 ">
        <div class="team-bio-content">
          <h3>Joy Mundt</h3>
          <h5 class="orange-title">Septilink™ Founder</h5>
          <p>Joy played a pivotal role as a founding member of Ranchview, a technology startup headquartered in Alberta, Canada,
            which later transitioned into a successful acquisition by Raven Industries in 2009. Her multifaceted contributions
            encompassed website development, proficient back-office management, and effective operational leadership. Joy
            became a US citizen in 2017 and loves her home and the culture in Texas.  With a keen understanding of technology,
            Joy's expertise transcends various sectors, including agriculture, construction, and transportation.</p>
        </div>
      </div>
      <div class="col-lg-1 col-md-1 ">
      </div>
      <div class="col-lg-6 col-md-12 text-center">
        <div class="person">
          <img src="assets/img/team/bio/JoyMundtSeptilinkFounder.jpg" alt="Photo of Joy Mundt Septilink Founder">
        </div>
      </div>
    </div>
  </div>
</section>

<section id="amy" class="amy-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="person">
          <img src="assets/img/team/bio/AmyBarrowsSeptilinkSoftwareEngineer.jpg" alt="Photo of Amy Barrows Septilink Senior Staff Engineer">
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="team-bio-content">
          <h3>Amy Barrows</h3>
          <h5 class="orange-title">Senior Staff Engineer</h5>
          <p>Drawing upon over two decades of expertise, Amy seamlessly integrates her database and web application architecture
            proficiency across various industries. Her extensive portfolio spans healthcare to banking, encompassing diverse
            projects such as sales and marketing analytics for auto dealerships and embedded application development within
            precision agriculture. Amy's database design and architecture mastery enables her to craft robust solutions
            tailored to intricate business relationship modeling and sophisticated data-driven applications. Her applications
            and tools are distinguished by their scalability and efficiency in handling business workflows, all while
            prioritizing simplicity and intuitiveness. Amy's invaluable contributions are the bedrock for pioneering solutions
            within the IoT realm.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="jason" class="jason-area ptb-100">
  <div class="container">
    <div class="row align-items-center reverse-container">
      <div class="col-lg-6 col-md-12">
        <div class="team-bio-content">
          <h3>Jason Mundt</h3>
          <h5 class="orange-title">Product Manager</h5>
          <p>With a versatile background spanning Broadcast Engineering, Audio and Video Production, and Music and Arts
            performance, Jason brings a wealth of experience to his position at Septilink. He holds a degree in Media Arts
            from the University of North Texas, demonstrating his passion for fusing creativity with technology. Jason's
            skill set also encompasses a Maintenance Technician license accredited by the Texas Commission on Environmental
            Quality, alongside hands-on involvement in the Wastewater industry. Renowned for his unwavering dedication to
            integrity and customer satisfaction, Jason channels his expertise into crafting top-tier products at Septilink.
            Concurrently, he is pursuing a law degree, underscoring his commitment to continuous growth and deepening his
            understanding of the intersection between technology and legal frameworks.</p>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 text-center">
        <div class="person">
          <img src="../../../assets/img/team/bio/JasonMundtSeptilinkProductManager.jpg" alt="Photo of Jason Mundt Septilink Product Manager">
        </div>
      </div>
    </div>
  </div>
</section>

<section id="simon" class="simon-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="person">
          <img src="../../../assets/img/team/bio/SimonMcGibbonSeptilinkMarketingDirector.jpg" alt="Photo of Simon McGibbons Septilink Marketing">
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="team-bio-content">
          <h3>Simon McGibbon</h3>
          <h5 class="orange-title">Marketing Director</h5>
          <p>Simon's enthusiasm lies in crafting business strategies and fostering robust marketing, branding, and supply
            chain initiatives that harmonize with corporate objectives and propel business expansion. Armed with a degree
            from the School of Management Studies at the University of KwaZulu-Natal in South Africa, he embarked on his
            professional journey in Canada, spanning diverse sectors such as Oil and Gas, Food and Beverage, and Residential
            Construction. Simon's comprehension of Automation Technologies and strategic management prowess make him an
            invaluable asset to Septilink, where he leads the Marketing efforts.</p>
        </div>
      </div>
    </div>
  </div>

  <section id="david" class="david-area ptb-100">
    <div class="container">
      <div class="row align-items-center reverse-container">
        <div class="col-lg-6 col-md-12">
          <div class="team-bio-content">
            <h3>David Garrison</h3>
            <h5 class="orange-title">Business Development</h5>
            <p>With an extensive Green Industry background spanning over thirty years, David has a deep understanding of the
              environmental challenges in the residential and commercial space. He has a Bachelor's degree in Landscape
              Architecture from Texas Tech University and accreditation as a TBAE Registered Professional Landscape Architect.
              David is also a licensed Irrigator by the Texas Commission on Environmental Quality. His career began at Garrison
              Gardens, Inc., where he held the positions of Vice President and Landscape Architect, overseeing the successful
              completion of more than a thousand landscape construction projects. His expertise in customer service, project
              management, and team leadership shone through during this tenure. Transitioning to roles such as Commercial Sales
              Manager at DIG Corporation and later as a Technical Sales Representative at Calsense, David’s talent for leveraging
              innovative technologies has solidified his reputation as a leader and advocate for environmental stewardship within
              the industry.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 text-center">
          <div class="person">
            <img src="../../../assets/img/team/bio/DavidGarrisonSeptilinkBusinessDevelopment.jpg" alt="Photo of David Garrison Septilink Business Development">
          </div>
        </div>
      </div>
    </div>
  </section>


