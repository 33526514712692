import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from  '@angular/material/dialog';

@Component({
  selector: 'app-dealer-interest-form',
  templateUrl: './dealer-interest-form.component.html',
  styleUrls: ['./dealer-interest-form.component.scss']
})
export class DealerInterestFormComponent {

  constructor(private  dialog:  MatDialog, private  dialogRef:  MatDialogRef<DealerInterestFormComponent>, @Inject(MAT_DIALOG_DATA) public  data:  any) {

  }

  public  closeMe() {
    this.dialogRef.close();
  }
}
