<div id="news" class="main-banner pb-50">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="hero-content pt-50">
                    <h1 class="orange-title">Industry News</h1>
                    <p class="ps-1"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="articles" class="article-area pb-50">
    <div class="container">
        <div class="article-item-widget">
            <article class="item" *ngFor="let article of articles">
                <a href="{{article.link}}" class="thumb" target="_blank">
                    <span class="fullimage cover" role="img" style="background-image: url('{{article.image}}')"></span>
                </a>
                <div class="info">
                    <h6 class="title"><a href="{{article.link}}" target="_blank">
                        {{article.title}}</a></h6>
                    <time dateTime="{{article.publishDate}}">{{article.publishDateString}}</time>
                </div>
            </article>
        </div>
    </div>
</section>
