<div class="content-page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <h2>Message Sent</h2>
      </div>
    </div>
  </div>
</div>

<section class="contact-area pb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-12">
        <div class="contact-info">
          <div class="title">
            <h2>Speak with a team member</h2>
          </div>

          <ul class="contact-info-list">
            <li>
              <i class="fas fa-phone-volume"></i>
              <span>Phone</span>
              <a href="tel:5125929647">(512) 592-9647</a>
            </li>

            <!--
            <li>
              <i class="fas fa-map-marker-alt"></i>
              <span>Address</span>
              TBD
            </li>
            -->

            <li>
              <i class="fas fa-envelope-open-text"></i>
              <span>Email</span>
              <a href="mailto:hello@septilink.com">hello@septilink.com</a>
            </li>

            <li>
              <i class="fas fa-mobile-screen"></i>
              <span>Beta Testing</span>
              <a href="mailto:beta@septilink.com">beta@septilink.com</a>
            </li>
            <li>
              <i class="fas fa-users"></i>
              <span>Investor Inquiries</span>
              <a href="mailto:invest@septilink.com">invest@septilink.com</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-7 col-md-12">
        <div class="confirm-message">
          <i class="fas fa-envelope-circle-check contact-confirm"></i>
          <h4>Thank you for your interest, a team member will be in touch with you shortly.</h4>
        </div>
      </div>
    </div>
  </div>
</section>
