import { NgModule, NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from './app.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PreloaderComponent } from './common/preloader/preloader.component';
import { NavbarComponent } from './common/navbar/navbar.component';
import { FooterComponent } from './common/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactFormComponent } from './sections/contact/contact-form/contact-form.component';
import { ContactInfoComponent } from './sections/contact/contact-info/contact-info.component';
import { ConfirmComponent } from './sections/contact/confirm/confirm.component';
import { TeamComponent } from './sections/team/team.component';
import { PrivacyComponent } from './sections/privacy/privacy.component';
import { TermsComponent } from './sections/terms/terms.component';
import { FaqComponent } from './sections/faq/faq.component';
import { SocialComponent } from './common/social/social.component';
import { CustomerComponent } from './customer/customer.component';
import { InvestingComponent } from './sections/investing/investing.component';
import { IndustryNewsComponent } from './sections/industry-news/industry-news.component';
import { Sl5Component } from './sections/products/sl5/sl5.component';
import { DealerInterestFormComponent } from './sections/contact/dealer-interest-form/dealer-interest-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ValuesComponent } from './sections/values/values.component';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    ContactFormComponent,
    ContactInfoComponent,
    ConfirmComponent,
    TeamComponent,
    PrivacyComponent,
    TermsComponent,
    FaqComponent,
    SocialComponent,
    CustomerComponent,
    InvestingComponent,
    IndustryNewsComponent,
    Sl5Component,
    DealerInterestFormComponent,
    ValuesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
