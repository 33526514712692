<div id="sl5" class="main-banner-two ">
    <div class="spec-header-section">
        <div class="row">
            <div class="col-lg-6 col-md-6 spec-header-area-left">
                <div class="spec-header-title-left">
                    Available Fall 2024
                </div>
            </div>
            <div class="col-lg-6 col-md-6 spec-header-area-right">
                <div class="spec-header-title-right">
                    SL-5
                </div>
                <div class="spec-header-sub-title">
                    24/7 Septic System Monitoring
                    <br/>
                    <br/>
                    <span>PATENT PENDING</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div id="spec-navbar" class="col-lg-12 spec-nav-container">
                <a class="btn btn-white scroll-link" href="#features">Features</a>
                <a class="btn btn-white scroll-link" href="#specifications">Specifications</a>
                <a class="btn btn-white scroll-link" href="#video">Video</a>
                <a class="btn btn-light scroll-link" href="#downloads">Downloads</a>
            </div>
        </div>
    </div>
    <div class="spec-content">
        <div id="features">
            <div class="row v-center">
                <div class="container features-container">
                    <div class="row v-center">
                        <div class="col-lg-1 col-md-1"></div>
                        <div class="col-lg-3 col-md-12 feature-area">
                            <p class="feature-title">REMOTE MONITORING</p>
                            <p class="feature-content">
                                The Septilink Mobile App provides real-time status and sends notifications
                                immediately if a problem is detected.
                            </p>
                            <br/>
                            <p class="feature-title">RESIDENTIAL SEPTIC APPLICATIONS</p>
                            <p class="feature-content">
                                Designed as a drop-in remote monitoring
                                device, the SL-5 is installed between the electrical panel and
                                septic controller of any new or existing system to monitor the
                                pump, air pressure, power, and alarms.
                            </p>
                            <br/>
                            <p class="feature-title">LAUNCHING FALL 2024</p>
                            <p class="feature-content">
                                The SL-5 is in final testing and approval stages with commercial
                                release expected Fall 2024. If you are interested in purchasing,
                                <a href="mailto:sales@septilink.com" class="citation-link">click here</a> to reach out to us.
                            </p>
                        </div>
                        <div class="col-lg-4 col-md-12 product-image-container ">
                            <img src="assets/img/products/Septilink_SL-5_3DModel_rev0c.png" class="product-image"/>

                        </div>
                        <div class="col-lg-3 col-md-12 feature-area">
                            <p class="feature-title">BECOME A SEPTILINK DEALER</p>
                            <p class="feature-content">
                                Elevate customer satisfaction levels and peace of mind with the Septilink SL-5 and Mobile App.
                                The SL-5 seamlessly integrates with almost any residential septic system without changing the septic
                                system controller. By leveraging real-time and historical system insights through 24/7 monitoring, the SL-5
                                empowers you to boost monthly revenue while trimming service and maintenance expenses.
                            </p>
                            <p class="feature-content">
                                The Septilink Mobile App equips you and your Maintenance
                                Technicians with a comprehensive field tool that displays system status and historical data for unparalleled
                                operational understanding.
                            </p>
                            <p class="feature-content">
                                Let Septilink technology serve as your sales representative, presenting customers
                                with objective data reflecting actual system performance and problems.
                            </p>
                            <p class="dealer-button-content">
                                <a class="btn btn-orange btn-dealer-interest" href="mailto:dealer@septilink.com">Become a Dealer</a>
                            </p>
                        </div>
                        <div class="col-lg-1 col-md-1"></div>
                    </div>
                </div>
            </div>
            <div class="row v-center" id="video">
                <div class="container video-container">
                    <div class="row video-content">
                        <div class="col-lg-12 col-md-12 text-center video-iframe-container">
                            <iframe width="1120" height="630" src="https://www.youtube.com/embed/JQqylw2fNSs?version=3&autoplay=1&mute=1&loop=1&playlist=JQqylw2fNSs&modestbranding=1&rel=0" title="SL-5 24/7 Septic Monitoring System - INSTALLATION VIDEO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="row v-center pt-50" id="dealer">
                <div class="container dealer-container">
                    <div class="row reverse-container align-items-center">
                        <div class="col-lg-2 col-md-12 text-md-center">

                        </div>
                        <div class="col-lg-8 col-md-12 v-center dealer-content">
                            <div class="section-title">
                                <h2>Become a Dealer</h2>
                                <h5>Are you a Septic Professional?</h5>
                                <p class="dealer-content-text">
                                    You can elevate customer satisfaction levels and peace of mind with the Septilink SL-5 and Mobile App.
                                    The SL-5 seamlessly integrates with almost any
                                    residential septic system without changing the septic system controller. By leveraging real-time and
                                    historical system insights through 24/7 monitoring, the SL-5 empowers you to boost monthly revenue
                                    while trimming service and maintenance expenses. The Septilink Mobile App equips you and your Maintenance
                                    Technicians with a comprehensive field tool that displays system status and historical data for unparalleled
                                    operational understanding. Let Septilink technology serve as your sales representative, presenting customers
                                    with objective data reflecting actual system performance and problems.
                                </p>
                                <p class="dealer-button-content">
                                    <a class="btn btn-orange btn-dealer-interest" href="mailto:dealer@septilink.com">Become a Septilink Dealer</a>
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-12 text-md-center">

                        </div>
                    </div>
                </div>
            </div>-->
            <div class="row v-center pt-50" id="technology">
            <div class="container technology-container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="technology-title">
                            Remote Monitoring Technology
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="technology-sub-title">
                            Data at your fingertips
                        </div>
                    </div>
                </div>
                <div class="row technology-content v-center">
                    <div class="col-lg col-md-1"></div>
                    <div class="col-lg-3 col-md-12 technology-features-container">
                        <div class="row">
                            <div class="col-lg-2 technology-feature-icon">
                                <img src="assets/img/products/SeptilinkPumpOperationIcon.png"/>
                            </div>
                            <div class="col-lg-10 technology-feature-item">
                                <p class="technology-feature-title">PUMP OPERATION</p>
                                <p class="technology-feature-content">
                                    See when your pump is running with pump-run times and cycle count. Determine 
                                    if your system is running as designed. Common issues such as surface water contamination, 
                                    system overload, clogged pump/filters, and electrical deterioration can be observed before the system fails.
                                </p>
                            </div>
                        </div>
                        <div class="row pt-50">
                            <div class="col-lg-2 technology-feature-icon">
                                <img src="assets/img/products/SeptilinkAlarmNotificationsIcon.png"/>
                            </div>
                            <div class="col-lg-10 technology-feature-item">
                                <p class="technology-feature-title">ALARM NOTIFICATIONS</p>
                                <p class="technology-feature-content">
                                    Receive immediate notifications on your cell phone when an alarm event is
                                    triggered. Have the necessary information to diagnose issues from the
                                    historical data in the mobile app.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 mobile-app-container">
                        <img src="assets/img/products/SeptilinkMobileApp2.png" class="mobile-app-image"/>
                    </div>
                    <div class="col-lg-3 col-md-12 technology-features-container">
                        <div class="row">
                            <div class="col-lg-2 technology-feature-icon">
                                <img src="assets/img/products/SeptilinkDataAnalyticsIcon.png"/>
                            </div>
                            <div class="col-lg-10 technology-feature-item">
                                <p class="technology-feature-title">DATA ANALYTICS</p>
                                <p class="technology-feature-content">
                                    By tracking critical indicators such as power use, aerator pressure,
                                    and pump run patterns, Septilink can detect symptoms of common failure
                                    points for septic systems before they become critical.
                                </p>
                            </div>
                        </div>
                        <div class="row pt-50">
                            <div class="col-lg-2 technology-feature-icon">
                                <img src="assets/img/products/SeptilinkConnectServiceProviderIcon.png"/>
                            </div>
                            <div class="col-lg-10 technology-feature-item">
                                <p class="technology-feature-title">CONNECT WITH A MAINTENANCE PROVIDER</p>
                                <p class="technology-feature-content">
                                    The Septilink app can provide your maintenance provider with the data to diagnose and service your system accurately.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg col-md-1"></div>
                </div>
            </div>
        </div>
        </div>

        <div class="row v-center" id="specifications">
            <div class="container specifications-container">
                <div class="row pt-25">
                    <div class="col-lg-12 col-md-12">
                        <div class="specifications-title">
                           Innovative and Reliable
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-12"></div>
                    <div class="col-lg-5 col-md-12 specifications-left">
                        <div class="row">
                            <table class="specification-table">
                                <tr>
                                    <th colspan="2">Electrical</th>
                                </tr>
                                <tr>
                                    <td>Operating Power</td>
                                    <td>120VAC 20 Watts</td>
                                </tr>
                                <tr>
                                    <td>Max Current</td>
                                    <td>120VAC 30 Amps</td>
                                </tr>
                            </table>
                        </div>

                        <div class="row">
                            <table class="specification-table">
                                <tr>
                                    <th colspan="2">Environmental</th>
                                </tr>
                                <tr>
                                    <td>Temperature</td>
                                    <td>-30°C - 60°C<br/>(-22°F - 140°F)</td>
                                </tr>
                                <tr>
                                    <td>Submersion</td>
                                    <td>NEMA 6P</td>
                                </tr>
                                <tr>
                                    <td>Enclosure Rating</td>
                                    <td>Type 4X, 12, 6P, and 13</td>
                                </tr>
                            </table>
                        </div>

                        <div class="row">
                            <table class="specification-table">
                                <tr>
                                    <th colspan="2">Certifications</th>
                                </tr>
                                <tr>
                                    <td>UL</td>
                                    <td>Pending</td>
                                </tr>
                                <tr>
                                    <td>Enclosure UL</td>
                                    <td>Type 4X, 12, 6P, and 13<br/>
                                        UL 1741<br/>
                                        UL 7463
                                    </td>
                                </tr>
                                <tr>
                                    <td>FCC ID</td>
                                    <td>APV-2630MB</td>
                                </tr>
                                <tr>
                                    <td>IC</td>
                                    <td>5843C-2630MB</td>
                                </tr>
                            </table>
                        </div>

                        <div class="row">
                            <table class="specification-table">
                                <tr>
                                    <th colspan="2">Physical Dimensions</th>
                                </tr>
                                <tr>
                                    <td>Dimensions</td>
                                    <td>
                                        8.59” x 6.59” x 5.06”<br/>
                                        218.186mm x 167.386mm x 128.524mm
                                    </td>
                                </tr>
                                <tr>
                                    <td>Weight</td>
                                    <td>
                                        3.5 lbs<br/>
                                        1.6 kg
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mounting</td>
                                    <td>Feet suitable for uneven surfaces.</td>
                                </tr>
                                <tr>
                                    <td>Material</td>
                                    <td>Polycarbonate</td>
                                </tr>
                            </table>
                        </div>

                        <div class="row">
                            <table class="specification-table">
                                <tr>
                                    <th colspan="2">Features</th>
                                </tr>
                                <tr>
                                    <td>Easy Connect, Tool-free WAGO Wire Terminals</td>
                                </tr>
                                <tr>
                                    <td>Reliable Pass-Through Operation</td>
                                </tr>
                                <tr>
                                    <td>Surge Protection with LED</td>
                                </tr>
                                <tr>
                                    <td>Internal Status LEDs</td>
                                </tr>
                                <tr>
                                    <td>Hinged Cabinet with Door Open Detection</td>
                                </tr>
                                <tr>
                                    <td>Battery Backup</td>
                                </tr>
                                <tr>
                                    <td>1 Year Warranty</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-12 specifications-right">
                        <div class="row">
                            <table class="specification-table">
                                <tr>
                                    <th colspan="2">Monitoring</th>
                                </tr>
                                <tr>
                                    <td>Power Consumption (Pump, Aerator, Link)</td>
                                </tr>
                                <tr>
                                    <td>Air Pressure (Aerator)</td>
                                </tr>
                                <tr>
                                    <td>Power Loss Detection</td>
                                </tr>
                                <tr>
                                    <td>Power Surge Detection</td>
                                </tr>
                                <tr>
                                    <td>Pump Run Time and Cycle Count</td>
                                </tr>
                                <tr>
                                    <td>System Location</td>
                                </tr>
                                <tr>
                                    <td>Alarms (High Level, Low/High Air Pressure, Power Fail, Door Open, Aux Input)</td>
                                </tr>
                                <tr>
                                    <td>Network Status (Carrier, Signal Strength, 3G/4G/5G)</td>
                                </tr>
                            </table>
                        </div>

                        <div class="row">
                            <table class="specification-table">
                                <tr>
                                    <th colspan="2">Installer Connections (J1)</th>
                                </tr>
                                <tr>
                                    <td>1 - Input Power Line</td>
                                    <td>AWG 8-24 120VAC 30 Amps</td>
                                </tr>
                                <tr>
                                    <td>2 - Input Power Neutral</td>
                                    <td>AWG 8-24</td>
                                </tr>
                                <tr>
                                    <td>3 - Input Power Ground</td>
                                    <td>AWG 8-24</td>
                                </tr>
                                <tr>
                                    <td>4 - Output Power Ground</td>
                                    <td>AWG 8-24 120VAC 30 Amp</td>
                                </tr>
                                <tr>
                                    <td>5 - Output Power Line</td>
                                    <td>AWG 8-24 120VAC 30 Amp</td>
                                </tr>
                                <tr>
                                    <td>6 - Output Power Neutral</td>
                                    <td>AWG 8-24</td>
                                </tr>
                                <tr>
                                    <td>7 - High Level Input</td>
                                    <td>AWG 8-24 120VAC</td>
                                </tr>
                                <tr>
                                    <td>8 - Aerator Input</td>
                                    <td>AWG 8-24 120VAC</td>
                                </tr>
                                <tr>
                                    <td>9 - Aux Input (AC)</td>
                                    <td>AWG 8-24 120VAC</td>
                                </tr>
                            </table>
                        </div>

                        <div class="row">
                            <table class="specification-table">
                                <tr>
                                    <th colspan="2">Enclosure Connections (J2)</th>
                                </tr>
                                <tr>
                                    <td>1 - Door Switch -</td>
                                    <td>AWG 14-26</td>
                                </tr>
                                <tr>
                                    <td>2 - Door Switch +</td>
                                    <td>AWG 14-26 12VDC</td>
                                </tr>
                                <tr>
                                    <td>3 - Aux Ground -</td>
                                    <td>AWG 14-26</td>
                                </tr>
                                <tr>
                                    <td>4 - AuxInput +</td>
                                    <td>AWG 14-26 12VDC</td>
                                </tr>
                            </table>
                        </div>

                        <div class="row">
                            <table class="specification-table">
                                <tr>
                                    <th colspan="2">Communications (J3)</th>
                                </tr>
                                <tr>
                                    <td>Cellular/Network</td>
                                    <td>LTE CAT-M1 GSM Fallback</td>
                                </tr>
                                <tr>
                                    <td>Cellular Antenna</td>
                                    <td>Internal</td>
                                </tr>
                                <tr>
                                    <td>GPS Antenna</td>
                                    <td>Internal</td>
                                </tr>
                                <tr>
                                    <td>Battery</td>
                                    <td>Internal 1100mAh</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-12"></div>
                </div>
                <div class="row">
                    <div class="col-lg-1 col-md-12"></div>
                    <div class="col-lg-10 col-md-12 specifications-disclaimer-content">
                        ©2024 Septilink. All Specifications are typical and subject to change without notice.
                        All other trademarks are the property of their respective owners.
                        PATENT PENDING
                    </div>
                    <div class="col-lg-1 col-md-12">
                    </div>
                </div>
            </div>
        </div>
        <div class="row v-center" id="downloads">
            <div class="container downloads-container">
                <div class="row">
                    <div class="col-lg-3 col-md-12 ">
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="downloads-title">
                            SL-5 Documents & Downloads
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 ">
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-12 ">
                    </div>
                    <div class="col-lg-4 col-md-12 downloads-content">
                        <ul>
                            <li><a class="citation-link" href="assets/pdf/Septilink_SL-5_Specifications.pdf" type="application/octet-stream" download="Septilink_SL-5_Specifications.pdf"><i class="fas fa-file"></i>Specifications PDF</a></li>
                            <li><a class="citation-link" href="assets/pdf/Septilink_SL-5_InstallerDiagram.pdf" type="application/octet-stream" download="Septilink_SL-5_InstallerDiagram.pdf"><i class="fas fa-diagram-project"></i>Installer Diagram</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-md-12 ">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


