import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig, MatDialogContent, MatDialogActions } from  '@angular/material/dialog';
import { DealerInterestFormComponent } from "../sections/contact/dealer-interest-form/dealer-interest-form.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent {
  constructor(private  dialog:  MatDialog) { }

  ngOnInit() {
  }

  openDealerInterestForm()
  {
    let dialogRef = this.dialog.open(DealerInterestFormComponent, {
      panelClass: "dialog-responsive",
      height: '90vh',
      maxWidth: '100vw',
      maxHeight: '90vh',
      data: { },
    });
  }
}
