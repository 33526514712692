<nav id="navbar-scrollspy" class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/septilink_logo.png" class="logo-img" height="45" alt="image"></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item"><a class="nav-link scroll-link" href="/">Home</a></li>
            <!--<li class="nav-item"><a class="nav-link" href="#features">Features</a></li>-->
            <li class="nav-item"><a class="nav-link scroll-link" href="#about">Our Story</a></li>
            <li class="nav-item"><a class="nav-link scroll-link" href="#dealer">Dealer</a></li>
            <li class="nav-item"><a class="nav-link" routerLink="/products">Products</a></li>
            <li class="nav-item"><a class="nav-link" routerLink="/team">Team</a></li>
            <li class="nav-item"><a class="nav-link" routerLink="/values">Values</a></li>
        </ul>
        <div class="others-options">
            <a routerLink="/contact" class="default-btn">Contact</a>
        </div>
    </div>
</nav>
