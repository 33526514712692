<div id="privacy" class="main-banner-two">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-12">
        <div class="hero-content pt-70">
          <h1>Privacy Policy</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
        </div>
      </div>
      <div class="col-lg-1 col-md-1">

      </div>
      <div class="col-lg-6 col-md-12">
        <div id="banner-img"></div>
      </div>
    </div>
  </div>
</div>
