<div id="home" class="main-banner-two home-area">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="home-hero-title align-items-center">

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 col-md-12">
        <div class="home-hero-content">
        </div>
      </div>

      <div class="col-lg-7 col-md-12">

        <div class="banner-image">
          <div class="tagline-content float-end">
            <p>
              Fewer <i>Worries</i>
              <br/>
              More <i>Memories</i>
            </p>
          </div>
        </div>
      </div>
    </div>
</div>

<section id="about" class="about-area">
  <div class="">
    <div class="row align-items-center">
      <div class="col-lg-12 col-md-12">
        <div class="section-title">
          <h2>Our Story</h2>
          <p>Septilink™, headquartered in North Texas, is an environmental technology company dedicated to providing
            affordable and efficient solutions for homeowners and wastewater industry professionals. Our mission includes
            preserving Texas' stunning landscapes, which often suffer from problematic wastewater systems. As homeowners
            ourselves, we experienced firsthand the frustration and concern caused by unreliable septic systems, impacting
            not only us but also our community. Drawing inspiration from automation advancements in industries like HVAC,
            irrigation, and security, we recognized the potential for technology to dramatically improve the homeowner
            experience. In 2022 we assembled a team of top-tier, Internet of Things professionals to develop Septilink™
            technology. After nearly two years of intense design and testing, we proudly introduce our inaugural product,
            the <a href="/products" class="citation-link">Septilink SL-5</a>, to the wastewater industry.</p>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-12 col-md-12">
        <div class="about-image">
        </div>
      </div>
    </div>
  </div>
</section>

<section id="map" class="map-area">
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="map-image">
      </div>
    </div>
  </div>
</section>

<section id="dealer" class="dealer-area">
  <div class="container dealer-container">
    <div class="row reverse-container align-items-center">
      <div class="col-lg-5 col-md-12 text-md-center dealer-video">
        <iframe id="dealer-iframe" src="https://www.youtube.com/embed/JQqylw2fNSs?version=3&autoplay=1&mute=1&loop=1&playlist=JQqylw2fNSs&modestbranding=1&rel=0" title="SL-5 24/7 Septic Monitoring System - INSTALLATION VIDEO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div class="col-lg-7 col-md-12 v-center dealer-content">
        <div class="section-title">
          <h2>Become a Dealer</h2>
          <h5>Are you a Septic Professional?</h5>
          <p class="dealer-content-text">
            You can elevate customer satisfaction levels and peace of mind with the Septilink SL-5 and Mobile App. The SL-5 seamlessly integrates with almost any
            residential septic system without changing the septic system controller. By leveraging real-time and
            historical system insights through 24/7 monitoring, the SL-5 empowers you to boost monthly revenue
            while trimming service and maintenance expenses. The Septilink Mobile App equips you and your Maintenance
            Technicians with a comprehensive field tool that displays system status and historical data for unparalleled
            operational understanding. Let Septilink technology serve as your sales representative, presenting customers
            with objective data reflecting actual system performance and problems.
          </p>
          <p class="dealer-button-content">
            <a class="btn btn-orange btn-dealer-interest" href="mailto:dealer@septilink.com">Become a Septilink Dealer</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
