<div id="home" class="vr-main-banner-two home-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="home-hero-title">
                    <h1>Septic system tanking your backyard parties?</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="home-hero-content">
                    <p>Are the neighbors complaining on Facebook about the smell?  Or maybe you've moved into your
                        dream home, you have a beautiful property to enjoy, but what are those purple sprinklers? Why
                        is the buzzer going off in the middle of the night? </p>
                    <p class="mt-lg-3" >Free yourself from worry with the Septilink™ smart controller. Imagine the
                        ability to control, schedule, and monitor your septic system from any device, anywhere, 24/7!</p>
                    <a href="#" class="btn btn-light">Explore More</a>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="banner-image">
                </div>
            </div>
        </div>
    </div>
</div>

<section id="features" class="features-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="vr-about-image">
                    <img src="assets/img/home/HandDeviceSeptilink.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="control-features-content">
                    <h2>Control is at your fingertips</h2>
                    <p>Spend more time doing things you love. Let Septilink™ bring you the convenience of automated
                        control with a single tap of your finger. Septilink™ is a drop in replacement septic controller
                        and monitoring service that gives homeowners and service providers the ability to manage the
                        septic system from a mobile app and proactively provide notifications when the system is in
                        need of service.</p>

                    <div class="about-features">
                        <div class="row m-0">
                            <div class="col-lg-4 col-6 col-md-4 p-0">
                                <div class="features-box">
                                    <i class="flaticon-maintenance"></i>
                                    <h3>24/7 Monitoring</h3>
                                </div>
                            </div>

                            <div class="col-lg-4 col-6 col-md-4 p-0">
                                <div class="features-box">
                                    <i class="flaticon-remote-control"></i>
                                    <h3>Access Anywhere</h3>
                                </div>
                            </div>

                            <div class="col-lg-4 col-6 col-md-4 p-0">
                                <div class="features-box">
                                    <i class="flaticon-layers"></i>
                                    <h3>Easy To Use App</h3>
                                </div>
                            </div>

                            <div class="col-lg-4 col-6 col-md-4 p-0">
                                <div class="features-box">
                                    <i class="flaticon-tap"></i>
                                    <h3>One Touch Activation</h3>
                                </div>
                            </div>

                            <div class="col-lg-4 col-6 col-md-4 p-0">
                                <div class="features-box">
                                    <i class="flaticon-target"></i>
                                    <h3>Predictive Analytics</h3>
                                </div>
                            </div>

                            <div class="col-lg-4 col-6 col-md-4 p-0">
                                <div class="features-box">
                                    <i class="flaticon-check-square"></i>
                                    <h3>Notifications</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="notifications" class="notifications-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="section-title">
                    <h2>Let our technology take care of the dirty work</h2>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="vr-about-image">
                    <img src="assets/img/home/DirtyHands.png" alt="image">
                </div>
            </div>
        </div>
        <div class="row notification-features-content align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-features">
                    <div class="row m-0">
                        <div class="col-lg-4 col-6 col-md-4 p-0">
                            <div class="features-box">
                                <i class="flaticon-maintenance"></i>
                                <h3>Power Failure Notifications</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-6 col-md-4 p-0">
                            <div class="features-box">
                                <i class="flaticon-target"></i>
                                <h3>High Level Alarms</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-6 col-md-4 p-0">
                            <div class="features-box">
                                <i class="flaticon-layers"></i>
                                <h3>Maintenance Reminders</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-6 col-md-4 p-0">
                            <div class="features-box">
                                <i class="flaticon-tap"></i>
                                <h3>Automatic Service Contract Submissions</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-6 col-md-4 p-0">
                            <div class="features-box">
                                <i class="flaticon-remote-control"></i>
                                <h3>Programming of Run Times</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-6 col-md-4 p-0">
                            <div class="features-box">
                                <i class="flaticon-check-square"></i>
                                <h3>Service Provider Notification & Dispatch</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="about" class="about-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <h2>What percentage of US homes have septic systems?</h2>
                    <p>According to the U.S. Census Bureau, more than 60 million people in the nation are
                        served by septic systems. That means about 26 million homes (one-fourth of all homes) in America.
                        It is estimated that nearly half of these septic systems work inadequately. That is one big
                        stinky, dirty problem, and we are here to change that. </p>
                    <p>Septilink™ is a tech startup dedicated to developing innovative solutions for septic system problems.
                        Our mission is to enhance our customers lives and environments through outstanding products and value.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/home/HandDeviceGreenHome.png" alt="image" width="600">
            </div>
        </div>
    </div>
</section>

<section class="explore-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="explore-content">
            <h2>We would love to partner with you</h2>

            <p>Are you interested in becoming a beta tester? Or perhaps a Septilink™
                Dealer? Let's get in touch!</p>
            <div class="fa-align-left">
                <app-contact-form></app-contact-form>
            </div>
        </div>
    </div>
</section>

