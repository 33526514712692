import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {ContactInfoComponent} from "./sections/contact/contact-info/contact-info.component";
import {ConfirmComponent} from "./sections/contact/confirm/confirm.component";
import {TeamComponent} from "./sections/team/team.component";
import {PrivacyComponent} from "./sections/privacy/privacy.component";
import {TermsComponent} from "./sections/terms/terms.component";
import {FaqComponent} from "./sections/faq/faq.component";
import {CustomerComponent} from "./customer/customer.component";
import {InvestingComponent} from "./sections/investing/investing.component";
import {IndustryNewsComponent} from "./sections/industry-news/industry-news.component";
import {Sl5Component} from "./sections/products/sl5/sl5.component";
import {ValuesComponent} from "./sections/values/values.component";

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'contact', component: ContactInfoComponent, pathMatch: 'full' },
  { path: 'confirm', component: ConfirmComponent, pathMatch: 'full' },
  { path: 'team', component: TeamComponent, pathMatch: 'full' },
  { path: 'faq', component: FaqComponent, pathMatch: 'full' },
  { path: 'privacy', component: PrivacyComponent, pathMatch: 'full' },
  { path: 'terms', component: TermsComponent, pathMatch: 'full' },
  { path: 'customer', component: CustomerComponent, pathMatch: 'full' },
  { path: 'investing', component: InvestingComponent, pathMatch: 'full' },
  { path: 'industry-news', component: IndustryNewsComponent, pathMatch: 'full' },
  { path: 'products', component: Sl5Component, pathMatch: 'full' },
  { path: 'values', component: ValuesComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
