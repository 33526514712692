import { Article } from './article';

export const ARTICLES: Article[] = [
    { id: 6, title: 'Septilink Inc. Announces the Launch of SL-5 - Residential Septic Monitoring', publishDate: '2024-03-04', publishDateString: 'March 4, 2024', link: '../../../assets/pdf/Septilink_SL-5_PressRelease1.pdf', image: '../../../assets/img/logo/septilink_icon_gray_full_color.png'},
    //{ id: 5, title: 'Texas A&M AgriLife to host homeowner sewage maintenance class', publishDate: '2023-10-16', publishDateString: 'October 16, 2023', link: 'https://www.hcnews.com/stories/texas-am-agrilife-to-host-homeowner-sewage-maintenance-class,18725', image: '../../../assets/img/footer/article-5.png' },
    { id: 4, title: 'Commissioners vote 3-2 in bid to shift septic maintenance to homeowners', publishDate:'2023-04-13', publishDateString: 'April 13, 2023', link: 'https://www.hcnews.com/stories/commissioners-vote-3-2-in-bid-to-shift-septic-maintenance-to-homeowners,3390', image: '../../../assets/img/footer/article-4.png' },
    { id: 2, title: 'Nearly half of global septic systems work inadequately', publishDate: '2022-09-12', publishDateString: 'September 12, 2022', link: 'https://www.newswise.com/articles/nearly-half-of-global-septic-systems-work-inadequately-uf-scientist-urges-safety-in-wake-of-climate-change', image: '../../../assets/img/footer/article-2.png' },
    { id: 1, title: 'Backed-up pipes, stinky yards: Climate change is wrecking  septic tanks', publishDate: '2022-04-12', publishDateString: 'April 12, 2022', link: 'https://www.washingtonpost.com/climate-environment/2022/04/12/backed-up-pipes-stinky-yards-climate-change-is-wrecking-septic-tanks/', image: '../../../assets/img/footer/article-1.png' },
    { id: 3, title: 'Texas water quality and septic systems', publishDate: '2020-08-10', publishDateString: 'August 10, 2020', link: 'https://twri.tamu.edu/news/2020/august/texas-water-quality-and-septic-systems/', image: '../../../assets/img/footer/article-3.png' },

];