<form id="contactForm" [formGroup]="createForm" (ngSubmit)="onCreate(createForm.value)">
  <div class="row">
    <div class="col-lg-6 col-md-6">
      <div class="form-group">
        <input type="text" class="form-control" name="fullName" placeholder="Your name" id="name" formControlName="fullName">
      </div>
    </div>

    <div class="col-lg-6 col-md-6">
      <div class="form-group">
        <input type="text" class="form-control" name="phone_number" id="phone_number" placeholder="Phone number" formControlName="phoneNumber">
      </div>
    </div>

    <div class="col-lg-12 col-md-12">
      <div class="form-group">
        <input type="email" class="form-control" name="email" id="email" placeholder="Email address" formControlName="email">
      </div>
    </div>

    <div class="col-lg-12 col-md-12">
      <div class="form-group">
        <textarea name="message" class="form-control" id="message" placeholder="Write message" cols="30" rows="7" formControlName="msg"></textarea>
      </div>
    </div>

    <div class="col-lg-12 col-md-12">
      <div class="form-group">
        <label for="is_beta_tester" class="form-check-label">Interested in becoming a test user?</label>
        <label class="switch">
          <input type="checkbox" id="is_beta_tester" formControlName="isBetaTester" value="true" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="col-lg-12 col-md-12">
      <div class="form-group">
        <label for="is_investor" class="form-check-label" value="true">Interested in Investing?</label>
        <label class="switch">
          <input type="checkbox" id="is_investor" formControlName="isInvestor" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 mt-lg-2">
      <button type="submit" class="btn btn-orange btn-right-rounded ms-1 mt-1">Send Message</button>
    </div>
  </div>
</form>
