import { Component } from '@angular/core';
import { Article } from "../../common/models/article";
import { ARTICLES } from "../../common/models/news-articles";

@Component({
  selector: 'app-industry-news',
  templateUrl: './industry-news.component.html',
  styleUrls: ['./industry-news.component.scss']
})
export class IndustryNewsComponent {
  articles = ARTICLES;

}
