import { Component, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { APIService, ContactData } from '../../../API.service';
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactFormComponent {
  public createForm: FormGroup;

  constructor(private api: APIService, private builder: FormBuilder, private router: Router) {
    this.createForm = this.builder.group({
      fullName: '',
      email: '',
      phoneNumber: '',
      msg: '',
        isBetaTester: false,
        isInvestor: false
    })
  }

  public onCreate(contactData: ContactData) {
    this.api
        .CreateContactData(contactData)
        .then(() => {
          console.log('item created!');
          this.createForm.reset();
          this.router.navigate(['/confirm', { success: true }]);
        })
        .catch((e) => {
          console.log('error creating contact...', e);
        });
  }
  ngOnInit() {
  }
}
