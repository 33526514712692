import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactInfoComponent {
    constructor() { }

    ngOnInit() {
    }
}
