<div id="investing" class="main-banner">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="hero-content pt-70">
                    <h1>Investing</h1>
                    <p>Septilink™ is currently funded but future investment opportunities
                        may become available. Contact us if you would like to be notified about future
                        opportunities.</p>
                    <a routerLink="/contact" class="btn btn-orange btn-right-rounded mtb-60">Contact Us</a>
                </div>
            </div>
            <div class="col-lg-1 col-md-1">

            </div>
            <div class="col-lg-6 col-md-12">
                <div id="banner-img"></div>
            </div>
        </div>
    </div>
</div>




