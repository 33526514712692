<h2 mat-dialog-title>
    <mat-dialog-actions class="float-end">
        <a mat-button mat-dialog-close class="">X</a>
    </mat-dialog-actions>
</h2>
<mat-dialog-content id="dealer-interest-content">
    <iframe id="dealer-interest-iframe" src="https://docs.google.com/forms/d/e/1FAIpQLSeBt1kAqPKUYzBsBBp7R-DSOtA_AjqcCzxgfjbaR0lFXtr2ag/viewform?embedded=true"
           frameborder="0" marginheight="0" marginwidth="0">Loading…
    </iframe>
</mat-dialog-content>


