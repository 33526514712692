import { Component, OnInit } from '@angular/core';
import {APIService, ContactData, EmailList} from '../../API.service';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {ARTICLES} from "../models/news-articles";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public createForm: FormGroup;
  submitted = false;
  submittedMsg = '';
  articles = ARTICLES;
  constructor(private api: APIService, private builder: FormBuilder, private router: Router) {
    this.createForm = this.builder.group({
      email: ''
    })
  }

  public onCreate(emailList: EmailList) {
    this.api
        .CreateEmailList(emailList)
        .then(() => {
          console.log('item created!');
          this.createForm.reset();
          this.submitted = true;
          this.submittedMsg = "Thank you for signing up to be notified. We look forward to updating you on our progress."
          //TODO display confirmation to user
        })
        .catch((e) => {
          console.log('error creating contact...', e);
            this.submitted = true;
            this.submittedMsg = "We're sorry an error has occurred while submitting this request."

        });
  }

  ngOnInit() {
  }

}
