<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>

                    <ul class="support-list">
                        <li><a href="/contact">Contact Us</a></li>
                        <li><a href="#about">Our Story</a></li>
                        <li><a href="/team">Team</a></li>
                        <li><a href="/values">Values</a></li>
                        <li><a href="assets/pdf/Septilink_SL-5_PressRelease1.pdf" type="application/octet-stream" download="Septilink_SL-5_PressRelease.pdf">Press Release</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="single-footer-widget">
                <h3>Industry News</h3>
                  <article class="item" *ngFor="let article of articles | slice:0:3">
                      <a href="{{article.link}}" class="thumb" target="_blank">
                          <span class="fullimage cover bg" role="img" style="background-image: url('{{article.image}}')"></span>
                      </a>
                      <div class="info">
                          <h4 class="title"><a href="{{article.link}}" target="_blank">{{article.title}}</a></h4>
                          <time dateTime="{{article.publishDate}}">{{article.publishDateString}}</time>
                      </div>

                      <div class="clear"></div>
                  </article>

                  <article class="item">
                      <a href="/industry-news" class="btn btn-right-rounded btn-orange btn-explore-news">Explore more news</a>
                      <div class="clear"></div>
                  </article>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-footer-widget">
                    <h3>Get In Touch</h3>
                    <div class="contact-content">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="contact-info-footer-container">
                                    <ul class="contact-info-list">
                                        <li>
                                            <i class="fas fa-phone-volume"></i>
                                            <span>Phone</span>
                                            <a href="tel:5127107969">(512) 710-7969</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="contact-info-footer-container">
                                    <ul class="contact-info-list">
                                        <li>
                                            <i class="fas fa-envelope-open-text"></i>
                                            <span>Email</span>
                                            <a href="mailto:support@septilink.com">support@septilink.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/Septilink/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://www.instagram.com/septilink_inc" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCocjca4wls2GwVXxwskdZvg" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/septilink/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>

            <!--
            <div class="col-lg-4 col-md-6">
                <div class="single-footer-widget">
                    <h3>Get Notified When We Launch</h3>

                    <div class="newsletter-content">
                        <div [hidden]="submitted">
                            <form id="emailSignupForm"
                                  [formGroup]="createForm"
                                  (ngSubmit)="onCreate(createForm.value)"
                                  class="newsletter-form"
                            >
                                <input type="email" class="input-newsletter" placeholder="Email Address" formControlName="email">
                                <button type="submit" class="btn-orange btn-right-rounded">Subscribe</button>
                            </form>
                            <p>We'll never share your email address</p>
                        </div>
                        <div [hidden]="!submitted">
                            <p class="newsletter-signup-message align-items-center">
                                <i class="fas fa-envelope-circle-check contact-confirm"></i>
                                <br/>
                                {{ submittedMsg }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>©2023 Septilink™ All Rights Reserved.</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <!--<li><a routerLink="/privacy">Privacy Policy</a></li>
                        <li><a routerLink="/terms">Terms & Conditions</a></li>-->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class="fas fa-arrow-up"></i></div>
