<div id="values" class="main-banner">
    <div class="container">
        <div class="row justify-content-md-center">
            <div class="col-lg-9 col-md-12">
                <div class="hero-content pt-70 text-center">
                    <h1>Our Values</h1>
                    <p class="ptb-15">
                        At Septilink, our business is driven by a mission-centered ethos rooted in our
                        dedication to honor God and serve others. Integrity, Excellence, Stewardship,
                        and Community are the pillars that guide our approach to every endeavor. Our
                        purpose extends to the responsible stewardship of all entrusted to us: our talents,
                        families, team members, customers, partners, and the world we inhabit.
                    </p>
                    <p>
                        We are grateful to reside in a nation founded on the principle that everyone is created
                        equal and free to pursue the American Dream. Septilink, founded in Texas, is dedicated to
                        delivering value to our customers locally and across the United States. With a steadfast
                        commitment to reliability and trustworthiness, we stand behind every aspect of our work.
                        Enhancing the homeowner experience and emphasizing environmental stewardship, we aspire to
                        provide innovation to enhance the quality of life for our customers and lay the foundation
                        for a brighter future.
                    </p>
                    <p class="pt-20 font-italic">
                        “Whatever you do, work at it with all your heart, as working for the Lord,”
                        <span class="citation">Colossians 3:23</span>
                    </p>
                    <p class="font-italic">
                        “Each of you should use whatever gifts you have received to serve others as faithful stewards
                        of God’s grace.”
                        <span class="citation">1 Peter 4:10</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
