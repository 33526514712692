import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {
  title = 'Septilink';
  location: any;
  routerSubscription: any;

  constructor(private router: Router) {
  }

  ngOnInit(){
    this.recallJsFunctions();
  }

  recallJsFunctions() {
    this.router.events
      .subscribe((event) => {
        if ( event instanceof NavigationStart ) {
          $('.preloader').fadeIn('slow');
        }
      });
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        $.getScript('../assets/js/main.js');
        $('.preloader').fadeOut('slow');
        $('.navbar-collapse').collapse('hide');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
            return;
        }
        let scrollToTop = window.setTimeout(function () {
            let pos = window.screenY;
            if (pos > 0) {
                window.scrollTo(0, pos - 20);
            } else {
                window.clearTimeout(scrollToTop);
            }
        }, 16);
      });
  }
}
